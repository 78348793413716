import {
  LOGICFLOW
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 获取流程设计列表
export async function listFlow(params) {
  return request(`${LOGICFLOW}/flow/listFlow`, METHOD.GET, params)
}

// 查询审批分组列表
export async function getFlowGroupList(params) {
  return request(`${LOGICFLOW}/flowGroup/getFlowGroupList`, METHOD.GET, params)
}

// 获取流程设计详细信息
export async function getFlowDesign(params) {
  return request(`${LOGICFLOW}/flow/getFlowDesign`, METHOD.GET, params)
}

// 新增流程设计
export async function createFlow(params) {
  return request(`${LOGICFLOW}/flow/createFlow`, METHOD.POST, params)
}

// 新增流程设计
export async function updateFlow(params) {
  return request(`${LOGICFLOW}/flow/updateFlow`, METHOD.POST, params)
}

// 编辑审批启用状态
export async function updateFlowStatus(params) {
  return request(`${LOGICFLOW}/flow/updateFlowStatus`, METHOD.POST, params)
}

// 删除审批设计
export async function deleteFlow(params) {
  return request(`${LOGICFLOW}/flow/deleteFlow`, METHOD.POST, params)
}

// 创建审批分组
export async function createFlowGroup(params) {
  return request(`${LOGICFLOW}/flowGroup/createFlowGroup`, METHOD.POST, params)
}

// 修改审批分组
export async function updateFlowGroup(params) {
  return request(`${LOGICFLOW}/flowGroup/updateFlowGroup`, METHOD.POST, params)
}

// 删除审批分组
export async function deleteFlowGroup(params) {
  return request(`${LOGICFLOW}/flowGroup/deleteFlowGroup`, METHOD.POST, params)
}

// 转移审批分组下的审批
export async function transferFlow(params) {
  return request(`${LOGICFLOW}/flowGroup/transferFlow`, METHOD.POST, params)
}

// 修改审批分组排序
export async function changeFlowGroupSort(params) {
  return request(`${LOGICFLOW}/flowGroup/changeFlowGroupSort`, METHOD.POST, params)
}

// 审批记录查询
export async function getflowRecordList(params) {
  return request(`${LOGICFLOW}/flowRecord/list`, METHOD.GET, params)
}

// 审批记录删除
export async function deleteflowRecord(params) {
  return request(`${LOGICFLOW}/flowRecord/remove`, METHOD.GET, params)
}

// 转交审批
export async function transferRecord(params) {
  return request(`${LOGICFLOW}/flowRecord/transferRecord`, METHOD.POST, params)
}

// 终止审批
export async function stopRecord(params) {
  return request(`${LOGICFLOW}/flowRecord/stopRecord`, METHOD.POST, params)
}

// 下载审批单
export async function downloadFlowDoc(params) {
  return request(`${LOGICFLOW}/flowRecord/downloadFlowDoc`, METHOD.POST, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 查询审批统计信息
export async function statistics(params) {
  return request(`${LOGICFLOW}/flowRecord/statistics`, METHOD.GET, params)
}

// 查询审批分组统计信息
export async function groupStatistics(params) {
  return request(`${LOGICFLOW}/flowRecord/groupStatistics`, METHOD.GET, params)
}

// 导出审批记录
export async function exportFlowRecord(params) {
  return request(`${LOGICFLOW}/flowRecord/exportFlowRecord`, METHOD.GET, params, { responseType: 'blob', headers: {
    isDownload: true
  }})
}

// 查询角色列表
export async function getFlowRoleList(params) {
  return request(`${LOGICFLOW}/flow/role/getFlowRoleList`, METHOD.GET, params)
}

// 查询角色详情
export async function getFlowRoleDetail(params) {
  return request(`${LOGICFLOW}/flow/role/getFlowRole`, METHOD.GET, params)
}

// 添加审批角色
export async function addFlowRole(params) {
  return request(`${LOGICFLOW}/flow/role/addFlowRole`, METHOD.POST, params)
}

// 添加审批角色
export async function updateFlowRole(params) {
  return request(`${LOGICFLOW}/flow/role/updateFlowRole`, METHOD.POST, params)
}

// 查询角色的人员详情
export async function getFlowRoleUser(params) {
  return request(`${LOGICFLOW}/flow/role/getFlowRoleUser`, METHOD.GET, params)
}

// 删除审批角色
export async function deleteFlowRole(params) {
  return request(`${LOGICFLOW}/flow/role/deleteFlowRole`, METHOD.GET, params)
}

// 删除审批角色下的人员
export async function deleteFlowRoleUser(params) {
  return request(`${LOGICFLOW}/flow/role/deleteFlowRoleUser`, METHOD.GET, params)
}

// 根据角色和部门查询审批人员
export async function getFlowAuthUserList(params) {
  return request(`${LOGICFLOW}/flow/role/getFlowAuthUserList`, METHOD.POST, params)
}

// 通过审批编码查询审批信息
export async function getFlowByCode(params) {
  return request(`${LOGICFLOW}/flow/getFlowByCode`, METHOD.GET, params)
}
